import React, {useState} from "react";

import Layout from "../components/Layout";

import ModalSuccess from '../components/ModalSuccess';
import ModalFail from '../components/ModalFail';

export default function Contact() {
    //Hooks
    const [isOpenSuccess, setIsOpenSuccess] = useState(false);
    const [isOpenFail, setIsOpenFail] = useState(false);


    const showModalSuccess = () => {
        setIsOpenSuccess(!isOpenSuccess);
    }

    const showModalFail = () => {
        setIsOpenFail(!isOpenFail);
    }

    const submitForm = (ev) => {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                showModalSuccess();
            } else {
                showModalFail();
            }
        };
        xhr.send(data);
    }

    return (
        <Layout>
            <div id="main">
                <ModalSuccess show={isOpenSuccess}
                              onClose={showModalSuccess}/>
                <ModalFail show={isOpenFail}
                           onClose={showModalFail}/>
                <div className="contact_section_banner">
                    <div className="contact_banner">
                        <h1>Contacto</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title" style={{fontWeight: 'bold'}}>Estamos aquí por tí</h1>
                        <p className="services_section_1__paragraph">¿Tengo una pregunta? ¿Necesitas información?,
                            complete el formulario para informarnos cómo
                            podemos ayudarlo. ¡Estamos emocionados de saber de usted!</p>
                    </div>
                    <section className="card-contact">
                        <article>
                            <form onSubmit={submitForm} action="https://formspree.io/f/mjvlzrdr" method="POST">
                                <input className="input-contact" type="text" name="name" placeholder="Nombre"
                                       id="name"
                                       required={true}/>
                                <input type="email" name="email" placeholder="Email" id="email" required={true}/>
                                <input type="tel" name="tel" placeholder="Teléfono" id="tel" required={true}/>
                                <textarea name="message" placeholder="Mensaje" id="message" rows="5"
                                          required={true}/>
                                <button type="submit">Enviar</button>
                            </form>
                        </article>
                        <article>
                            <div
                                style={{backgroundColor: '#ffffff', padding: 5, marginBottom: 10}}>
                                <dl style={{color: '#000000'}}>
                                    <dd style={{margin: 0, textAlign: 'center'}}>
                                        Calle La Montaña 28, Col. Los Pastores Naucalpan de Juarez, 53340, Estado de
                                        México
                                    </dd>
                                </dl>
                                <dl style={{color: '#000000'}}>
                                    <dd style={{margin: 0, textAlign: 'center'}}>admin@foxglove.pro</dd>
                                    <dd style={{margin: 0, textAlign: 'center'}}>+52 (55) 5344 6775</dd>
                                    <dd style={{margin: 0, textAlign: 'center'}}>Horario 9:00–18:00 HRS</dd>
                                </dl>
                            </div>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.2418539841906!2d-99.22344468502463!3d19.48822778685275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d202efdad6ce5f%3A0x95c1facdf88f554c!2sLa%20Monta%C3%B1a%2028%2C%20Los%20Pastores%2C%2053340%20Naucalpan%20de%20Ju%C3%A1rez%2C%20M%C3%A9x.!5e0!3m2!1ses-419!2smx!4v1654752704360!5m2!1ses-419!2smx"
                                className={{border: 0}}
                                width="100%" height="50%" frameBorder="0" allowFullScreen=""
                                aria-hidden="false" tabIndex="0">
                            </iframe>
                        </article>
                    </section>
                </div>
            </div>
        </Layout>
    )
}
